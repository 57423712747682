import Vue from 'vue'
import App from './App.vue'
import 'animate.css/animate.min.css'
import './assets/font.scss'
import vuetify from './assets/plugins/vuetify';
//test
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
