<template>
  <div class="g-strength">
    <div class="left hidden-xs-only">
      <v-icon>far fa-{{strength.icon}}</v-icon>
    </div>
    <div class="right">
      <div class="name">
        {{strength.name}}
      </div>
      <div class="assessment my-1">
        <v-icon v-for="(star, i) in stars" :key="i">
          {{rate(star)}}
        </v-icon>
         <span class="ml-2">- {{strength.level}}</span>
      </div>
      <div class="text">
        {{strength.line1}}<br>
        {{strength.line2}}
      </div>
    </div>
  </div>
</template>

<script>
export default{
  created(){
    let total;
    let partial = false;
    let rating = this.strength.rating;
    if(rating % 1 != 0){
      partial = true;
      total = Math.floor(rating)
    } else{
      total = rating;
    }
    let i = 1;
    for(i;i<total+1;i++){
      console.log(i, total)
      this.stars['star'+i] = "full"
    }
    if(partial){
      this.stars['star'+(total+1)] = "partial"
    }

  },
  data:()=>({
    stars:{
      star1:0,
      star2:0,
      star3:0,
      star4:0,
      star5:0
    }

  }),
  methods:{
    rate(rating){
      if(rating){
        if(rating == "full"){
          return "fas fa-star"
        } else {
          return "fas fa-star-half-alt"
        }
      }
      return "far fa-star"
    }
  },
  props:{
    strength:Object
  }
}
</script>

<style lang="scss" scoped>
.g-strength{
  font-size:1rem;
  display:flex;
  &:hover{
    .left .v-icon{
      animation:swing;
      animation-duration:1s;
    }
  }
  .left{
    text-align:center;
    width:100px;
    i.v-icon{
      font-size:2.75rem;
      margin-top:.5rem;
      opacity:.95;
    }
  }
  .name{
    font-size:1.5rem;
    color:#00BEFF;
    font-weight:900;
  }
  .assessment{
    display:flex;
    align-content:center;
    align-items:center;
    font-weight:800;

    span{
      color:#F7B357;
    }
    i.v-icon{
      font-size:1rem;
    }
  }
}

</style>
