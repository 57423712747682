<template>
  <div class="project-modal">

      <div class="project__image fw">
          <v-img @click="modal=true" :lazy-src="thumb" :src="img" height="400px" fill @load="isLoaded()">
            <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <small class="hint">Click image to enlarge</small>
          </v-img>

      </div>

      <v-row class="project__info">
          <v-col :cols="12" :md="8">
            <div class="project__title">
              {{project.alt_title || project.title}}
            </div>
              <div class="project__description" v-html="project.details"></div>
          </v-col>
          <v-col :cols="12" :md="4">
            <a target="_blank" :href="project.link" v-if="project.link" class="g-link mb-3" style="font-size:1rem;">
              <v-icon style="font-size:1rem;" left>fal fa-link</v-icon>
              VIEW SITE
            </a>
            <div class="project__tech">
              <h4>Applied Tech</h4>
              <ul>
                <li v-for="tech in project.tech" :key="'modtec'+tech">
                  {{tech}}
                </li>
              </ul>
            </div>
            <div class="project__skill mt-3">
              <h4>Applied Skill</h4>
              <ul>
                <li v-for="skill in project.skill" :key="'modski'+skill">
                  {{skill}}
                </li>
              </ul>
            </div>
          </v-col>
      </v-row>




    <v-dialog v-if="modal" width="1200" v-model="modal">
        <img :src="img" />
    </v-dialog>

  </div>
</template>

<script>
export default {
  created(){
    // this.img = require('@assets/images/'+this.project.slug+'.jpg') this.thumb = '/'
    this.img = '/images/'+this.project.slug+'.jpg'
    this.thumb = '/images/'+this.project.slug+'-thumb.jpg'
  },
  data(){
    return{
      modal:false,
      img:null,
      thumb:null,
      loaded:false,
    }
  },
  methods:{
    isLoaded(){
      console.log('testing')
      this.loaded = true;
    },
    // getThumb(){
    //   return require('@/assets/images/'+this.project.slug+'-thumb.jpg')
    // },
    // getImg(){
    //   return require('@/assets/images/'+this.project.slug+'.jpg')
    // }
  },
  props:{
    project:Object
  }
}
</script>
<style lang="scss">
.project__image{

  .v-image__image{
    background-position:top center !important;
  }
}
</style>
<style lang="scss" scoped>

.project__info{
  font-weight:300;
  padding-bottom:2rem;
}
.project__image{

  .v-image__image{
    background-position:top center !important;
  }
  cursor:pointer;
  position:relative;
  min-height:200px;
  .hint{
    position:absolute;
    bottom:.5rem;
    left:50%;
    transform:translateX(-50%);
    width:fit-content;
    height:auto;
    display:block;
    padding:.25rem;
    background:rgba(29,13,46,.87);
    font-weight:bold;
    color:white;
    content:"click image to enlarge";
    z-index:9999;
  }
  img{
    width:100%;
    height:auto;

    display:block;

  }
}
.g-link{
  margin-top:1rem;
  font-weight:900;
  text-decoration: none;
  color:#00BEFF;
  display:flex;
  align-content:center;
  i.v-icon{
    color:#00BEFF;
  }
}
.project__title{
  font-size:1.5rem;
  font-weight:800;
  align-items:center;

}
.project__tech{
  // font-style:italic;
}
.project__description{
  margin-top:.5rem;
  padding-bottom:1rem;
}
.project-modal{
  background:#20325B;
  .fw{
    margin-bottom:1rem;
  }
  >*{
    &:not(.fw){
      max-width:85%;
      margin:0 auto;
    }

  }
}
</style>
