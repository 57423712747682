<template>
  <v-app>
<!--
    <g-nav/> -->
    <v-main>
      <g-primary/>
    </v-main>
  </v-app>
</template>

<script>
import gPrimary from './views/main';
// import gNav from './components/Nav'

export default {
  name: 'App',

  components: {
    gPrimary
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">

.gh{
  font-family:'Fira Sans', 'Segoe UI', Arial;
  font-weight:900;
}
div.theme--dark.v-application{
  background: #1D0D2E;

  font-family:'Fira Sans', 'Segoe UI', Arial;
}
.g-nav{
  background:transparent !important;
}
</style>
