export const projects = {
  feature:[
    {
      title:"CCSO Forensify",
      description:"Workflow management utility for the Forensics Unit of the Charleston County Sherriff’s Office.",
      tech:["Vue.js", "GraphQL", "Laravel", "MySQL"],
      skill:["UX/UI", "Web Application Engineering", "Solutions Architecture", "Back-End Development"],
      slug:"ccso-forensify",
      details:"<p>Forensify was a two-man project where I served as the UX/UI Engineer, Front-End Developer, Co-Architect, and assisted lightly with Back-End development.</p><p>The Forensics Unit for Charleston County Sherriff's Office serves internal deputies/detectives as well as law officers with counties & municipalities spanning the entire South Carolina lowcountry.</p><p>Forensify is an information system that encompasses the lifecycle of the process through which law officers create, define, and submit 'items' (latent fingerprints, drugs, firearms, etc.) in the context of a 'Case' (i.e. legal matter), with any number of associated 'Requests' (desired action(s) to be performed on said item(s)). Other objects such as comments, attachments, individuals, businesses, etc. exist to allow for greater context.</p><p><b>Note:</b> The system itself is confidential and no public demonstration is available at this time. A live hands-on demonstration can be provided upon request.</p>"
    },
    {
      title:"CC Intranet",
      description:"Internal website used regularly by several hundred Charleston County employees for info & resource access.",
      details:"<p>Revamping the Intranet for Charleston County Government was a large and exciting undertaking. With several dozen departments, over 2000 employees, and thousands of resources, it was an excellent experience in learning how to approach the dissemination of large quantities of information across a broad spectrum of people with varying needs.</p><p><b>Note:</b> The system itself is confidential and no public demonstration is available at this time. A live hands-on demonstration can be provided upon request.</p>",
      tech:["Vue.js", "Laravel", "MySQL"],
      skill:["UX/UI", "Web Application Engineering", "Solutions Architecture"],
      slug:"cc-intranet",
    },
    {
      title:"Closing Cost Calculator",
      description:"Per the name, a tool for realtors to easily calculate closing costs for prospective transactions.",
      details:"<p>A straightforward utility built for RE/MAX Cornerstone Realty of Moncks Corner, SC. Allows for Realtors to quickly and easily calculate closing costs for a property, then prepare and present a printable version for clients.</p><p>A version is in the works to allow for anyone to register for the website and specify their own agent/agency details on the print screen. (ETA Q3 2021)</p>",
      tech:["Vue.js"],
      skill:["UX/UI", "Web Application Development"],
      slug:"closing-calc",
      link:"http://closingcalc.georgenobles.com"
    },
    {
      title:"Bowens Island",
      description:"Custom-designed Wordpress site for Bowens Island Restaurant on James Island, SC.",
      tech:["Wordpress", "PHP", "eCommerce"],
      skill:["SEO", "Web Design", "UX"],
      slug:"bowens",
      details:"<p>Bowens Island is equal in excellence as a client and as a restaurant. This was a fun WordPress website to build, and features eCommerce functionality which substantially grew the company's revenue via online merchandise sales.</p>",
      link:"https://bowensisland.com"
    }
  ],
  other:[
    {
      title:"DAC Project<br>Management",
      alt_title:"DAC Project Management",
      tech:["Vue.js", "GraphQL", "Strapi CMS", "ArcGIS"],
      skill:["Web Application Engineering", "UX/UI", "Solutions Architecture"],
      slug:"dac",
      details:"<p>A simple project/asset management utility built for the Building Service's 'Design and Construction' group of Charleston County Government.</p><p>While plainly minimalistic in style, it was an opportunity to showcase my ability to rapidly ship new front-end products by leveraging a custom-built methodology (code name 'Wyvern') that I'd gradually developed over the course of several projects.</p><p><b>Note:</b> The system itself is confidential and no public demonstration is available at this time. A live hands-on demonstration can be provided upon request.</p>"
    },{
      title:"CC Intranet View<br>Builder (WIP)",
      alt_title:"CC Intranet View Builder (WIP)",
      tech:["Vue.js", "GraphQL", "Strapi CMS"],
      skill:["UX/UI"],
      slug:"view-builder",
      details:"<p><b>Note:</b> This is a very rough concept for a project currently in progress.</p><p>The Intranet View Builder is a drag-and-drop WYSIWYG builder that I'm currently developing to simplify the creation & management of pages on the Charleston County Intranet. While it will immediately serve the Intranet specifically, it is being structured to accommodate the eventual integration of further usage for other county web assets.</p>"
    },{
      title:"Mouzone<br>Services",
      alt_title:"Mouzone Services",
      tech:["Wordpress"],
      skill:["Web Design", "SEO"],
      slug:"mouzone",
      details:"A simple economy-class website designed for a home remodeling/repair company local to the lowcountry.",
      link:"http://mouzoneservices.com"
    },{
      title:"Pratt & Co.<br>Real Estate",
      alt_title:"Pratt & Co. Real Estate",
      tech:["Wordpress", "PHP"],
      skill:["Web Design", "SEO", "UX"],
      slug:"pratt",
      link:"http://prattandco.com",
      details:"<p>A website for Pratt & Co. with RE/MAX Pro Realty in North Charleston, SC. Features divisions for both Residential and Commercial aspects, both for which users may perform custom property searches and make inquiries directly to the agency.</p><p><b>Note:</b> This site originally incorporated my own custom-made Wordpress plugin (Cosmic IDX) to allow for searching of property listings via CTARMLS. Due to economic reasons  Cosmic IDX became unsustainable, and was shelved in early 2020 and replaced with iHomeFinder. It is my intent to one day secure the time, attention, and funding to revive the Cosmic IDX effort.</p>"
    },{
      title:"Southern<br>Cosmetic Laser",
      alt_title:"Southern Cosmetic Laser",
      tech:["Wordpress"],
      skill:["Web Design", "SEO", "Copywriting", "UX"],
      slug:"scl",
      details:"One of the most content-heavy websites I'd ever developed and copywrote for, SCL's website featured nearly 100 pages to capture a number categories each containing a large number of related services.",
      link:"https://southerncosmeticlaser.com"
    },{
      title:"Charleston County<br>Graphic Design",
      alt_title:"Charleston County Graphic Design",
      tech:["Figma"],
      skill:["Graphic Design", "Strategy & Communications"],
      slug:"graphic-design",
      details:"A random assortment of Graphic Design products created during my employment with the Charleston County Government."
    }
  ]
}
