export const strengths = [
  {
    name:'Solutions Architecture',
    rating:4.5,
    line1:"Requirement gathering, Lifecycle planning,",
    line2:"Visual diagramming, Technical writing",
    level:"Advanced",
    icon:"drafting-compass"
  },{
    name:'Front-End + UX/UI',
    rating:4.5,
    line1:"Vue.js, HTML, CSS, Wireframes, User Flows,",
    line2:"Storyboards, Mock-ups",
    level:"Advanced",
    icon:"swatchbook"
  },{
    name:'Back-End + DevOps',
    rating:3,
    line1:"C#, PHP, Node.js, .NET, Laravel, Express,",
    line2:"Git, Azure, AWS, SQL, MySQL",
    level:"Intermediate",
    icon:"code"
  },
  {
    name:'Data + Business Intelligence',
    rating:3.5,
    line1:"Power BI, MS Dynamics 365, Analytics,",
    line2:"Reporting, Visualizations, Warehousing",
    level:"Advanced",
    icon:"file-chart-pie"
  },{
    name:'Strategy & Communications',
    rating:4,
    line1:"Project Management, Marketing & PR",
    line2:"Strategy, Copywriting",
    level:"Advanced",
    icon:"mind-share"
  },{
    name:'Athletics',
    rating:.5,
    line1:"Fun Fact: In 7th grade I benchwarmed for",
    line2:"the Cario Comets as 3rd string safety! 🏈",
    level:"Terrible",
    icon:"running"
  }
]
