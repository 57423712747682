<template>
  <div @click="act" class="g-project" :class="{mini}">
    <div class="g-title" v-if="! mini">
      {{project.title}}
    </div>
    <div class="g-title" v-else v-html="project.title">  </div>
    <div v-if="! mini" class="description">
      {{project.description}}
    </div>
    <div v-if="! mini" class="tech">
      <span v-for="(tech, i) in project.tech" :key="'selTech'+tech">{{tech}} <span v-if="i != project.tech.length - 1"> // </span></span>
    </div>
    <v-dialog width="900" v-model="dialog">
      <project-modal v-if="dialog" :project="project" />
    </v-dialog>
  </div>
</template>

<script>
import ProjectModal from './projects/index.vue'
export default {
  components:{ProjectModal},
  data(){
    return{
      dialog:false,
    }
  },
  computed:{
    // el:function(){
    //   if(! this.project.slug){
    //     return null
    //   }
    //   return ()=> import('./projects/'+this.project.slug);
    // }
  },
  methods:{
    act(){
      this.dialog = true;
    }
  },
  props:{
    project:Object,
    mini:Boolean
  }
}
</script>

<style lang="scss" scoped>
.g-project{

  transition:background .3s ease;
  cursor:pointer;
  background:#20325B;
  border-radius:12px;
  padding:1rem 1.5rem;
  &:hover{
    background:#4C8077;
  }
  .g-title{
    font-size:1.5rem;
    font-weight:900;
  }
  .description{
    font-weight:400;
  }
  .tech{
    font-weight:300;
    font-style:italic;
  }
  &.mini{
    padding:.75rem, 1.25rem;
    .g-title{
      font-size:1rem;
    }
  }
}
</style>
