<template>
  <div class="gn--main">
      <div class="upper-sections">
        <div class="upper-sections-inner">
          <section  class="feature-intro">
      <transition name="fade">

            <p v-if="step1">
              My name's <span class="txt-blue">George</span>.
            </p>
          </transition>
            <transition name="fade">
            <p v-if="step2">
              I'm a versatile problem-solver specializing in <span class="txt-blue">data</span>, <span class="txt-blue">digital design</span>, &amp; <span class="txt-blue">software engineering</span>.
            </p>
          </transition>
          </section>
      <transition name="fade">
          <section v-show="step5">
            <div class="section-header">
              <div>SKILL HIGHLIGHTS</div>
            </div>
      <div style="width:fit-content;margin:0 auto;">
        <p v-for="strength in strengths" :key="'str'+strength.name">
          <g-strength :strength="strength" />
        </p>
      </div>
          </section>
      </transition>
      <transition name="fade">
      <section v-if="step6">
        <div class="section-header">
          <div>RECENT PROJECTS</div>
        </div>
        <v-row>
          <v-col :cols="12" :md="6" v-for="project in projects.feature" :key="'proj'+project.title">
            <g-project :project="project" />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="6" :md="4" v-for="project in projects.other" :key="'proj'+project.title">
            <g-project mini :project="project" />
          </v-col>
        </v-row>
      </section>

      </transition>
      <transition name="fade">
      <section class="interests" v-if="step6">
        <div class="section-header">
          <div>INTERESTS</div>
        </div>
        <v-row>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-head-side-brain</v-icon>
              <div>Philosophy &amp; Literature</div>
            </div>
          </v-col>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-palette</v-icon>
              <div>Performing &amp; Visual Arts</div>
            </div>
          </v-col>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-chess-king</v-icon>
              <div>Strategy Games</div>
            </div>
          </v-col>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-swords</v-icon>
              <div>Writing &amp; World Building</div>
            </div>
          </v-col>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-atom</v-icon>
              <div>Science &amp; Technology</div>
            </div>
          </v-col>
          <v-col :cols="4">
            <div class="interest">
              <v-icon>fal fa-newspaper</v-icon>
              <div>Current Events &amp; Politics</div>
            </div>
          </v-col>
        </v-row>

      </section>

      </transition>
      <transition name="fade">
      <section class="interests" v-if="step6">
        <div class="section-header">
          <div>LET'S CONNECT!</div>
        </div>
        <div style="font-size:1.25rem;font-weight:600;width:500px;max-width:95%;margin:0 auto;" class="text-center">
          Email <a href="mailto:me@georgenobles.com">me@georgenobles.com</a> or click the button at the bottom right and drop me a message along with your contact info.<br><br>Thanks for stopping by!
        </div>

      </section>

      </transition>
        </div>

      </div>

<div class="final-section">
</div>
  </div>
</template>

<script>
import GStrength from '@/components/strength'
import GProject from '@/components/project.vue'
import { strengths } from '@/mixins/strengths.js'
import {projects} from '@/mixins/projects.js'
export default {
  components:{GStrength, GProject},
  mounted(){
    this.step1 = true;
    if(! this.dev)
      this.act = setInterval(this.display, 333);
  },
  methods:{
    display(){
      console.log('loop')
      if(this.at == this.steps - 1){
        clearInterval(this.act)
      } else {
        this.['step'+this.at] = true;
        this.at ++;
      }
    }
  },
  data(){
    return{
      strengths,
      projects,
      step1:false,
      step2:false,
      step3:false,
      step4:false,
      step5:false,
      step6:false,
      at:2,
      steps:8,
      act:null,
      dev:false,
    }
  }
}
</script>

<style lang="scss" scoped>
.interests{
  padding-bottom:1rem;
}
.interest{
  text-align:center;
  font-weight:600;

  &:hover{
    i.v-icon{
      animation:tada;
      animation-duration:.9s;
    }

  }

  i.v-icon{
    margin-bottom:.5rem;
    font-size:2rem;
    color:#F7B357;
  }
}
.upper-sections{
  position:relative;
  z-index:2;
//
// padding-bottom:100vh;
}
.upper-sections-inner{
  overflow: hidden;
  background: #1D0D2E;
  padding-bottom:5rem;
}
.final-section{
  display:none;
  // position:fixed;
  // top:0;
  // left:0;
  // height:100vh;
  // width:100vw;
  // background:#488CAB;
  // z-index:1;
}
.fade-enter-active, .fade-leave-active {
  position:relative;
  transition: all 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform:translateX(-1rem);
}
.section-header{
  font-weight:900;
  font-size:1.75rem;
  display:flex;
  align-items:center;
  margin-bottom:2.5rem;
  &:before{
    content:"";
    display:block;
    height:1px;
    background:#00BEFF;
    flex-grow:1;
    margin-right:2rem;
  }
  &:after{
    content:"";
    display:block;
    height:1px;
    background:#00BEFF;
    flex-grow:1;
    margin-left:2rem;
  }

}

section{
  p{
    position:relative;
    transition:left .5s;
  }
}
.feature-intro{
  font-size:2.75rem;
  font-weight:900;
}
@media (max-width:900px){
  .feature-intro{
    font-size:2rem;
  }
}
.txt-blue{
  color:#00BEFF;
}
.gn--main{


  section{
    width:700px;
    max-width:90%;
    margin:4rem auto 5rem;
    line-height:1.2;
  }
}
</style>
